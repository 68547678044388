import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import IndexLayout from "../components/index-layout"
import SEO from "../components/seo"

import "./main-contents.css"
import "./typography.css"

const AboutPage = () => {
    const data = useStaticQuery(graphql`query {
        about: allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/about/index.md$/"}}
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 1000
        ) {
            edges {
                node {
                    html
                }
            }
        }
        skill: allMarkdownRemark(
            filter: {fields: {collection: {regex: "/skill/"}}}
            sort: { fields: [frontmatter___order], order: ASC }
            limit: 1000
        ) {
            edges {
                node {
                    html
                    frontmatter {
                        title
                    }
                    fields {
                        collection
                    }
                }
            }
        }
        histories: allMarkdownRemark(
            filter: {fields: {collection: {regex: "/history/"}}}
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 1000
        ) {
            edges {
                node {
                    html
                    frontmatter {
                        title
                    }
                    fields {
                        collection
                    }
                }
            }
        }
    }`);

    const aboutMe = data.about.edges[0].node.html;
    const skills = data.skill.edges;
    const histories = data.histories.edges;

    return (
        <IndexLayout innerStyle="about">
            <SEO title="About" />
            <div style={{
                padding: "1.45rem"
            }} className="main-contents">
                <h1>About</h1>
                <h2>自己紹介</h2>
                <section dangerouslySetInnerHTML={{__html: aboutMe}} />
                <h2>略歴 (詳細 / 新しい順)</h2>
                <div>
                    {histories.map(({node}) => {
                        return (
                            <div>
                                <h3>{node.frontmatter.title}</h3>
                                <section dangerouslySetInnerHTML={{__html: node.html}} />
                            </div>
                        );
                    })}
                </div>
                <h2>技術・経験</h2>
                <p className="min-600-show" style={{
                    textAlign: "center",
                    margin: 0
                }}>← 左右スクロールできます →</p>
                <div className="grid-container about">
                    {skills.map(({node}) => {
                        return (
                            <div>
                                <h3>{node.frontmatter.title}</h3>
                                <section dangerouslySetInnerHTML={{__html: node.html}} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </IndexLayout>
    );
}

export default AboutPage